/*!********************************************************************************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??clonedRuleSet-13.use[1]!./node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-13.use[2]!./node_modules/resolve-url-loader/index.js??clonedRuleSet-13.use[3]!./node_modules/sass-loader/dist/cjs.js??clonedRuleSet-13.use[4]!./templates/rocket/submodules/promo/promo.scss ***!
  \********************************************************************************************************************************************************************************************************************************************************************************************************************************/
#promo {
  display: flex;
  flex-wrap: wrap;
}
#promo .promo-col {
  position: relative;
  height: 700px;
  padding: 0;
  overflow: hidden;
}
#promo .promo-col .promo-info {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
#promo .promo-col .promo-info h1, #promo .promo-col .promo-info h2 {
  font-family: var(--font-primary);
  text-align: center;
}
#promo .promo-col .promo-info h1 {
  color: #fff;
  font-size: 160px;
}
#promo .promo-col .promo-info h1:hover {
  color: #a87670;
}
#promo .promo-col .promo-info h2 {
  color: #a87670;
  text-transform: uppercase;
}
#promo .promo-col .promo-info .text {
  text-align: center;
  color: #fff;
  font-family: var(--secondary-font);
  font-size: 20px;
  line-height: 26px;
}
#promo .promo-col .promo-info .button {
  text-align: center;
}
#promo .promo-col .promo-info-left, #promo .promo-col .promo-info-right {
  padding-top: 10%;
}
#promo .promo-col .promo-info-left {
  padding-right: 60px;
  padding-left: 20%;
}
#promo .promo-col .promo-info-right {
  padding-left: 60px;
  padding-right: 20%;
}
#promo .promo-col .promo-left-bg {
  background: var(--src-promo-friday) no-repeat center center;
  right: 0;
}
#promo .promo-col .promo-right-bg {
  background: var(--src-promo-saturday) no-repeat center center;
  left: 0;
}
#promo .promo-col .promo-left-bg, #promo .promo-col .promo-right-bg {
  position: absolute;
  top: 0;
  background-size: cover;
  width: 100%;
  height: 100%;
  filter: brightness(0.5);
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}
#promo .promo-col-right:hover .promo-right-bg, #promo .promo-col-left:hover .promo-left-bg {
  transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
}

#modal_left, #modal_right {
  padding-right: 0 !important;
}
#modal_left .modal-dialog, #modal_right .modal-dialog {
  max-width: 800px;
  margin: 0.5rem auto;
}
#modal_left .modal-content, #modal_right .modal-content {
  padding: 30px 60px;
}
#modal_left .modal-content h5, #modal_right .modal-content h5 {
  font-family: var(--font-primary);
  color: #a87670;
  text-transform: uppercase;
  font-size: 60px;
}
#modal_left .modal-content p, #modal_left .modal-content li, #modal_right .modal-content p, #modal_right .modal-content li {
  color: #fff;
  font-family: var(--secondary-font);
  font-size: 20px;
  line-height: 26px;
}

body.active-light-mode #modal_left .modal-content p, body.active-light-mode #modal_left .modal-content li, body.active-light-mode #modal_right .modal-content p, body.active-light-mode #modal_right .modal-content li {
  color: var(--color-extra05);
}

@media (max-width: 1500px) {
  #promo .promo-col .promo-info h1 {
    font-size: 140px;
    line-height: 144px;
  }
  #promo .promo-col .promo-info .text {
    font-size: 17px;
    line-height: 23px;
  }
}
@media (max-width: 1050px) {
  #promo .promo-col .promo-info h1 {
    font-size: 120px;
    line-height: 120px;
  }
}
@media (max-width: 900px) {
  #promo .promo-col .promo-info h1 {
    font-size: 100px;
    line-height: 100px;
  }
  #promo .promo-col .promo-info .text {
    font-size: 16px;
    line-height: 22px;
  }
  #modal_left .modal-dialog, #modal_right .modal-dialog {
    max-width: 90%;
  }
}
@media (max-width: 768px) {
  #promo .promo-col {
    height: 500px;
  }
  #promo .promo-col .promo-info-left, #promo .promo-col .promo-info-right {
    padding-right: 10%;
    padding-left: 10%;
  }
  #promo .promo-col .promo-info .text {
    font-size: 14px;
    line-height: 20px;
  }
}
@media (max-width: 650px) {
  #modal_left .modal-content, #modal_right .modal-content {
    padding: 30px 20px;
  }
  #modal_left .modal-content p, #modal_left .modal-content li, #modal_right .modal-content p, #modal_right .modal-content li {
    font-size: 16px;
    line-height: 18px;
  }
}
@media (max-width: 500px) {
  #promo .promo-col .promo-info h1 {
    font-size: 74px;
    line-height: 74px;
  }
}
